<template>
  <div class="content">
    <TitleInfo title1="数据监控" title2="历史记录"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>设备类型：</div>
          <el-select
            v-model="deviceTypeID"
            placeholder="Select"
            @change="HandleChangeDeviceType"
          >
            <el-option
              v-for="item in typeInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">设备名称：</div>
          <el-select v-model="queryForm.deviceID" placeholder="Select">
            <el-option
              v-for="item in deviceInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">存储时间：</div>
          <el-date-picker
            v-model="dateRang"
            type="datetimerange"
            unlink-panels
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :shortcuts="shortcuts"
            format="YYYY-MM-DD HH:mm:ss"
            :clearable="false"
            style="width: 350px"
          >
          </el-date-picker>
          <el-button
            style="margin-left: 10px"
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
          <el-button type="warning" @click="Export"
            ><i class="iconfont icon-daochu" /><span style="margin-left: 4px"
              >导出</span
            ></el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
          v-loading="loading"
          :element-loading-text="loadingText"
          element-loading-background="rgba(122, 122, 122, 0)"
        >
          <el-table-column
            prop="DeviceTypeName"
            label="设备类型"
            min-width="150"
          ></el-table-column>
          <el-table-column
            prop="CustomName"
            label="客户名称"
            min-width="150"
          ></el-table-column>
          <el-table-column
            prop="DeviceName"
            label="设备名称"
            min-width="150"
          ></el-table-column>
          <el-table-column
            v-for="(item, index) in parameInfo"
            :key="index"
            :prop="item.Name"
            :label="item.Name"
            min-width="120"
          ></el-table-column>
          <el-table-column
            prop="CreateTime"
            label="存储时间"
            width="200"
          ></el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { Search, Refresh, Edit, Delete } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetHistoryDataPageList, ExportRecord } from '@/api/DataRecord'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import { GetDeviceParameList } from '@/api/DeviceParame'
import { GetDeviceFillListByTypeID } from '@/api/Device'
import moment from 'moment'
const queryForm = ref({
  deviceID: '',
  dtStart: new Date(),
  dtEnd: new Date().getTime() - 3600 * 1000 * 24 * 7,
  pageIndex: 1,
  pageSize: 10
})
const deviceTypeID = ref('')
const typeInfo = ref([])
const deviceInfo = ref([])
const parameInfo = ref([])
const loading = ref(true)
const loadingText = ref('数据加载中...')
//起止日期
const shortcuts = ref([
  {
    text: '最近一周',
    value: () => {
      return [moment().subtract(7, 'days').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近一个月',
    value: () => {
      return [moment().subtract(1, 'months').calendar(), moment().endOf('day')]
    }
  },
  {
    text: '最近三个月',
    value: () => {
      return [moment().subtract(3, 'months').calendar(), moment().endOf('day')]
    }
  }
])
const dateRang = ref([moment().startOf('day'), moment().endOf('day')])
const total = ref(0)
const tableData = reactive([])
onMounted(() => {
  GetDeviceTypeFillList()
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            typeInfo.value.push({
              ID: item.ID,
              Name: item.Name
            })
          })
          deviceTypeID.value = res.Data[0].ID
          initParameList()
          initDeviceList()
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
})
const initDeviceList = () => {
  deviceInfo.value.length = 0
  GetDeviceFillListByTypeID({ deviceTypeID: deviceTypeID.value })
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            deviceInfo.value.push({
              ID: item.ID,
              Name: item.Name
            })
          })
          queryForm.value.deviceID = res.Data[0].ID
          initGetList()
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}
const initParameList = () => {
  parameInfo.value.length = 0
  GetDeviceParameList({ deviceTypeID: deviceTypeID.value })
    .then((res) => {
      if (res) {
        if (res.Data.length > 0) {
          res.Data.forEach((item) => {
            if (item.ToHistory)
              parameInfo.value.push({
                Name: item.Name
              })
          })
        }
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
}
const initGetList = async () => {
  loadingText.value = '数据加载中...'
  loading.value = true
  total.value = 0
  tableData.length = 0
  queryForm.value.dtStart = moment(dateRang.value[0]).format(
    'yyyy-MM-D HH:mm:ss'
  )
  queryForm.value.dtEnd = moment(dateRang.value[1]).format('yyyy-MM-D HH:mm:ss')
  GetHistoryDataPageList(queryForm.value)
    .then((res) => {
      loading.value = false
      if (res) {
        total.value = res.Total
        tableData.length = 0
        if (res.Data.length > 0) {
          res.Data.forEach((element) => {
            tableData.push(JSON.parse(element))
          })
        }
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      loading.value = false
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const resetInfo = () => {
  queryForm.value = {
    userID: '',
    dtStart: new Date(),
    dtEnd: new Date().getTime() - 3600 * 1000 * 24 * 7,
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
const Export = () => {
  loadingText.value = '数据导出中...'
  loading.value = true
  queryForm.value.dtStart = `${dateRang.value[0].getFullYear()}-${
    dateRang.value[0].getMonth() + 1
  }-${dateRang.value[0].getDate()} ${dateRang.value[0].getHours()}:${dateRang.value[0].getMinutes()}:00`
  queryForm.value.dtEnd = `${dateRang.value[1].getFullYear()}-${
    dateRang.value[1].getMonth() + 1
  }-${dateRang.value[1].getDate()} ${dateRang.value[1].getHours()}:${dateRang.value[1].getMinutes()}:00`

  ExportRecord(queryForm.value)
    .then((response) => {
      let blob = new Blob([response.data], { type: response.data.type })
      // 针对于 IE 浏览器的处理, 因部分 IE 浏览器不支持 createObjectURL
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, response.fileName)
      } else {
        var downloadElement = document.createElement('a')
        var href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = response.fileName // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉 blob 对象
      }
      loading.value = false
    })
    .catch((err) => {
      loading.value = false
      console.log(err)
    })
}
const HandleChangeDeviceType = (e) => {
  queryForm.value.deviceID = ''
  total.value = 0
  tableData.length = 0
  initParameList()
  initDeviceList()
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
